<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12" v-if="orders.length">
      <div class="card card-body">
        <p>الطلبات السابقة</p>
        <div class="col-12 table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
              <th>رقم الطلب</th>
              <th>التاريخ</th>
              <th>المبلغ</th>
              <th>الحالة</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr v-for="order in orders" :key="order._id">
                <td>
                  {{ order.order_id }}
                </td>
                <td>
                  {{ order.date }}
                </td>
                <td>
                  {{ order.amount }}
                </td>
                <td>
                  <span class="btn btn-sm btn-warning" v-if="order.status == 0">
                    لم تتم
                  </span>
                  <span class="btn btn-sm btn-success" v-if="order.status == 1">
                    ناجحة
                  </span>
                  <span class="btn btn-sm btn-danger" v-if="order.status == 2">
                    فاشلة
                  </span>
                </td>
                <td>
                  <button
                    class="btn btn-block btn-sm btn-secondary"
                    v-if="order.status == 1"
                    @click="printSheet(order)"
                  >
                    <i class="fa fa-print"></i>
                    طباعة الفاتورة
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 c">
      <div class="card card-body text-center">
        <h4>اشتراك/تجديد الاشتراك</h4>
        <h2 class="text-success">اشتراك لمدة شهر مقابل 150 ريال فقط</h2>
        <h4>(هذا العرض ساري لنهاية هذا العام الدراسي)</h4>
        <div class="col-12 g">
          <button class="btn btn-lg btn-relief-success" v-b-modal.modal-buy>
            <i class="fa fa-check-square"></i>
            اشتراك الآن
          </button>
        </div>
      </div>
    </div>
    <div class="col-12"></div>
    <div class="col-12 col-lg-6 c">
      <div class="card card-body text-center">
        <h4>الاشتراك الحالي ينتهي في: {{ user.subscribe.exdate }}</h4>
      </div>
    </div>
    <!-- basic modal -->
    <b-modal id="modal-buy" title="اشتراك الآن" hide-footer>
      <table class="table table-bordered table-hover">
        <tbody>
          <tr>
            <th>مدة الإشتراك</th>
            <th>30 يوم من تاريخ الدفع</th>
          </tr>
        </tbody>
      </table>
      <div class="col-12 text-center">
        <button
          class="btn btn-lg btn-block btn-relief-success"
          v-if="!loading"
          @click="pay()"
        >
          <i class="fa fa-check-square"></i>
          الدفع الآن
        </button>
        <button
          class="btn btn-lg btn-block btn-relief-success disabled"
          disabled
          v-if="loading"
        >
          <div class="spinner-border" role="status"></div>
          جاري التحميل...
        </button>
      </div>
      <div class="col-12 g text-left">
        <img
          src="https://tahdir.net/assets/custom/img/visa.png"
          style="width: 30px; height: 30px"
          alt=""
        />&nbsp;&nbsp; الدفع بالفيزا <br />
        <img
          src="https://tahdir.net/assets/custom/img/apple-pay.png"
          style="width: 30px; height: 30px"
          alt=""
        />&nbsp;&nbsp; أبل باي <br />
        <img
          src="https://tahdir.net/assets/custom/img/bank.png"
          style="width: 30px; height: 30px"
          alt=""
        />&nbsp;&nbsp; التحويل البنكي (<a
          href="javascript:;"
          @click="$router.push('/contact')"
          >تواصل معنا</a
        >
        للتحويل البنكي)
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      loading: true,
      plan: 0,
      orders: [],
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    $.post(api + "/user/general/subscribe-list", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.loading = false;
        g.orders = r.response;
      })
      .catch(function () {
        g.loading = false;
        alert("حدث خطأ اثناء الاتصال بالخادم");
      });
  },
  methods: {
    pay() {
      var g = this;
      this.loading = true;
      $.post(api + "/user/general/subscribe-pay", {
        jwt: this.user.jwt,
        plan: this.plan,
      })
        .then(function (r) {
          if (r.status != 100) {
            g.loading = false;
            alert("حدث خطأ !");
          } else {
            $("body").append(`
            <form action="https://store.sdaa.com.sa/checkout" id="form" method="post">
                <input type="hidden" name="website" value="schoolbus" />
                <input type="hidden" name="data" value="${r.response.data}" />
                <input type="hidden" name="token" value="${r.response.token}" />
            </form>
            `);
            $("#form").submit();
          }
        })
        .catch(function () {
          g.loading = false;
          alert("حدث خطأ");
        });
    },
    printSheet(order) {
      var g = this;
      var win = window.open("", "mywin", "width=400,height=500");
      win.document.write(`
      <style>
        table{
          width: 100%;
        }
        table, td, th {
          border: 1px solid;
        }
        table {
          width: 100%;
          border-collapse: collapse;
        }
      </style>
        <div class="card-body text-center" dir='rtl'>
          <p>
              اسم المتجر: التوصيل المدرسي
              <br>
              اسم التاجر: شركة صدى
              <br>
              رقم تسجيل ضريبة القيمة المضافة: 310458863500003
          </p>
          <table class="table table-hover table-bordered">
              <tbody><tr>
                  <th>رقم العملية / رقم الطلب</th>
                  <th>${order.order_id}</th>
              </tr>
              <tr>
                  <th>الجوال</th>
                  <th>${g.user.phone}</th>
              </tr>
              <tr>
                  <th>الخدمة</th>
                  <th>التوصيل المدرسي</th>
              </tr>
              <tr>
                  <th>اجمالي المبلغ الخاضع للضريبة</th>
                  <th>${order.original} ريال</th>
              </tr>
              <tr>
                  <th>ضريبة القيمة المضافة 15%</th>
                  <th>${order.tax} ريال</th>
              </tr>
              <tr>
                  <th>اجمالي المبلغ مع الضريبة 15%</th>
                  <th>${order.amount} ريال</th>
              </tr>
              <tr>
                  <th>تاريخ الإشتراك</th>
                  <th>${order.date}</th>
              </tr>
              <tr>
                  <th>اسم العميل</th>
                  <th>${g.user.name}</th>
              </tr>
            </tbody></table>
                <center class="col-12 text-center">
                <br>
                    <img src="https://chart.googleapis.com/chart?chs=150x150&amp;cht=qr&amp;chl=https://brmja.com&amp;chld=L|1&amp;choe=UTF-8" alt="">
                <br>
                <button style='background: green; color: white; font-size: 16px; padding: 10px' onclick="this.style.display = 'none'; window.print()">طباعة الفاتورة الآن</button>
                </center>
            </div>
      `);
    },
  },
};
</script>

<style>
</style>